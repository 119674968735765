<template>
  <div>
    <div class="">
      <div class="card-header border-0 pt-5">
        <h3 class="card-title align-items-start flex-column">
          <span v-if="data?.table_info?.name == 'departments'" class="card-label fw-bolder fs-3 mb-1">
            Müşteriler
          </span>
          <span v-else-if="data?.table_info?.name == 'bayi_odeme_gecmisi'" class="card-label fw-bolder fs-3 mb-1">
            Ödeme Geçmişiniz
          </span>
          <template v-else-if="data?.table_info?.name == 'bayi_bildirimler'"></template>
          <span v-else class="card-label fw-bolder fs-3 mb-1">{{
            data?.table_info?.display_name
          }}</span>
          <span class="text-muted mt-1 fw-bold fs-7">Toplam {{ dataCount }} adet kayıt bulunmaktadır</span>
        </h3>
        <!--Test-->
        <div class="card-toolbar">
          <div class="d-flex justify-content-center align-items-center">
            <button v-if="button_visible.filters" class="btn btn-light-info btn-icon-info btn-text-info me-2"
              @click="filterVisible = !filterVisible">
              <i class="ki-duotone ki-calendar fs-1"><span class="path1"></span><span class="path2"></span></i>
              Ay/Yıl Filtreleme</button>
              <template v-if="this.searches.column == undefined"></template>
            <input v-else type="text" class="form-control" id="search" :placeholder="this.searches.placeholder"
              v-model="searched" @keyup="searchTable(this.searches.column)" />
            <button v-if="button_visible.export" class="btn btn-light-success btn-icon-success btn-text-success" style="min-width: 220px;"
              @click="exportData">
              <i class="ki-duotone ki-file fs-1"><span class="path1"></span><span class="path2"></span></i>
              Çıktı Al</button>
            
            <template v-if="button_visible.create == true">
              <router-link :to="'/create/' + tableName" class="btn btn-sm btn-primary ms-2" style="white-space: nowrap">
                <span class="svg-icon svg-icon-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                      transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                    <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor"></rect>
                  </svg>
                </span>
                Yeni Oluştur
              </router-link>
            </template>
          </div>
        </div>
      </div>
      <template v-if="filterVisible">
        <div class="d-flex flex-column">
          <div class="d-flex flex-wrap">
            <el-radio-group v-model="radio2" size="large" class="m-1">
              <el-radio-button v-for="year in years" :key="year" :label="year"></el-radio-button>
            </el-radio-group>
          </div>
          <div class="d-flex flex-wrap">
            <div v-for="month in months" :key="month">
              <el-button @click="changeFilters(month.date, month.date2)">{{ month.name }}</el-button>
            </div>
          </div>
        </div>
      </template>
      <div v-if="this.dataCount < 1" class="card-body py-3">
        <div class="d-flex justify-content-center py-10">
          Hiç veri kaydınız bulunmamaktadır.
        </div>
      </div>
      <div v-else>
        <div class="card-body py-3">
          <div class="table-responsive table-loading">
            <table class="table gs-2 gy-4" id="table" v-loading="loading">
              <thead>
                <tr class="fw-bolder text-gray-700 bg-light">
                  <th v-for="td in data?.columns" :key="td.id" class="cursor-pointer"
                    :class="visible[td.name] == false ? 'd-none' : ''">
                    <div @click="sortBy(td)" title="Sırala" style="white-space: nowrap" data-toggle="tooltip">
                      <span style="margin-right: 4px">{{
                        td.display_name
                      }}</span>
                      <i class="bi bi-sort-down-alt fs-5" v-if="sorts[td.name] == true"></i>
                      <i class="bi bi-sort-up fs-5" v-else-if="sorts[td.name] == false"></i>
                      <i class="bi bi-arrow-down-up fs-5" v-else></i>
                    </div>
                  </th>
                  <th v-if="button_visible.update == true ||
                    button_visible.delete == true ||
                    button_visible.detail == true ||
                    button_visible.detay == true
                    " class="cursor-pointer">
                    Ayarlar
                  </th>
                  <template v-else></template>
                </tr>
              </thead>
              <tbody v-if="data.all_records_count < 1" class="text-muted">
                <div
                  class="d-flex flex-column justify-content-center align-items-center align-content-center text-center">
                  <i class="las la-archive" style="font-size: 50px"></i>
                  <div>
                    {{ data.table_info.display_name }} tablosunda kayıt
                    bulunamadı.
                  </div>
                </div>
              </tbody>
              <tbody v-else>
                <tr v-for="tr in data?.records" :key="tr" :id="tr.id" @click="
                  button_visible.details == true
                    ? detailRoutePush(tr.id)
                    : null
                  " :class="button_visible.details == true ? 'cursor-pointer' : ''
    ">
                  <template v-for="td in data?.columns" :key="td.id">
                    <template v-if="visible[td.name] == false"></template>
                    <td v-else>
                      <div class="d-flex align-items-center justify-content-start">
                        <div class="d-flex justify-content-start flex-column">
                          <div v-if="td.gui_type_name == 'date'" class="fw-bold">
                            {{
                              new Intl.DateTimeFormat("tr").format(
                                new Date(tr[td.name])
                              )
                            }}
                          </div>
                          <div v-else-if="td.name == 'talep_tutar' ||
                              td.name == 'tutar' ||
                              td.name == 'hakedis'
                              " class="fw-bold">
                            {{ formatter.format(tr[td.name]) }}₺
                          </div>
                          <div v-else-if="td.name == 'musteri_paketi_id'" class="fw-bold">
                            <span v-if="tr[td.name] == 'Ön muhasebe'" class="badge badge-lg badge-light-primary">{{
                              tr[td.name] }}</span>
                            <span v-else-if="tr[td.name] == 'Muhasebe'" class="badge badge-lg badge-light-success">{{
                              tr[td.name] }}</span>
                            <span v-else-if="tr[td.name] == 'Kobi'" class="badge badge-lg badge-light-info">{{ tr[td.name]
                            }}</span>
                            <span v-else class="badge badge-lg badge-light-secondary">{{ tr[td.name] }}</span>
                          </div>
                          <div v-else-if="td.name == 'arama_durumu'" class="fw-bold">
                            <span v-if="tr[td.name] == true" class="badge badge-lg badge-light-danger">Kapalı</span>
                            <span v-else class="badge badge-lg badge-light-success">Açık</span>
                          </div>
                          <div v-else-if="td.name == 'bayi_odeme_turu_id'" class="fw-bold">
                            <span v-if="tr[td.name] == 'Satış Ödemesi'" class="badge badge-lg badge-light-primary">{{
                              tr[td.name] }}</span>
                            <span v-else-if="tr[td.name] == 'Hakediş Ödemesi'" class="badge badge-lg badge-light-info">{{
                              tr[td.name] }}</span>
                            <span v-else-if="tr[td.name] == 'Ödeme İptali'" class="badge badge-lg badge-light-danger">{{
                              tr[td.name] }}</span>
                            <span v-else class="badge badge-lg badge-light-secondary">Tanımsız</span>
                          </div>
                          <div v-else-if="td.name == 'e_hizmet_turu_ids'" class="fw-bold">
                            <div>
                              <span v-for="eht in JSON.parse(tr['e_hizmet_turu_ids'])" :key="eht"
                                class="badge badge-lg badge-light-primary">{{ eht.display }}</span>
                            </div>
                          </div>
                          <div v-else-if="td.name == 'fatura_dosyasi'" class="fw-bold">
                            <div v-for="ad in JSON.parse(tr['fatura_dosyasi'])" :key="ad">
                              <a :href="this.baseUrl +
                                ad['disk'] +
                                '/' +
                                ad['destination_path'] +
                                ad['file_name']
                                " download target="_blank" class="text-dark" title="İndir"><i
                                  class="las la-download fs-2x"></i></a>
                            </div>
                          </div>
                          <div v-else-if="td.name == 'dekont'" class="fw-bold">
                            <div v-for="ad in JSON.parse(tr['dekont'])" :key="ad">
                              <a :href="this.baseUrl +
                                ad['disk'] +
                                '/' +
                                ad['destination_path'] +
                                ad['file_name']
                                " download target="_blank" class="text-dark" title="İndir"><i
                                  class="las la-download fs-2x"></i></a>
                            </div>
                          </div>
                          <div v-else-if="td.name == 'donusturulmus_dosyalar'" class="fw-bold">
                            <div v-for="dd in JSON.parse(
                              tr['donusturulmus_dosyalar']
                            )" :key="dd">
                              <a :href="this.baseUrl +
                                dd['destination_path'] +
                                dd['file_name']
                                " download title="İndir"><i class="las la-download fs-2x"></i></a>
                            </div>
                          </div>
                          <div v-else-if="td.name == 'garanti_belgesi'" class="fw-bold">
                            <div v-for="dd in JSON.parse(tr['garanti_belgesi'])" :key="dd">
                              <a :href="this.imgBaseUrl +
                                dd['destination_path'] +
                                dd['file_name']
                                " download title="İndir"><i class="las la-download fs-2x"></i></a>
                            </div>
                          </div>
                          <div v-else-if="td.name == 'islem_fiyat' ||
                            td.name == 'musteri_fiyati'
                            " class="fw-bold">
                            {{ this.formatter.format(tr[td.name]) }}₺
                          </div>
                          <div v-else-if="td.name == 'cevap_durumu'" class="fw-bold">
                            <span v-if="tr[td.name] == false" class="badge badge-lg badge-light-danger">Cevap
                              Verilmedi</span>
                            <span v-else-if="tr[td.name] == true"
                              class="badge badge-lg badge-light-success">Cevaplandı</span>
                            <span v-else class="badge badge-lg badge-light-secondary">Tanımsız</span>
                          </div>
                          <div v-else-if="td.name == 'kullanici_durumu'" class="fw-bold">
                            <span v-if="tr[td.name] == false" class="badge badge-lg badge-light-danger">PASİF</span>
                            <span v-else-if="tr[td.name] == true"
                              class="badge badge-lg badge-light-success">AKTİF</span>
                            <span v-else class="badge badge-lg badge-light-secondary">Tanımsız</span>
                          </div>
                          <div v-else-if="td.name == 'sure_oto_yenile'" class="fw-bold">
                            <span v-if="tr[td.name] == false" class="badge badge-lg badge-light-danger">PASİF</span>
                            <span v-else-if="tr[td.name] == true"
                              class="badge badge-lg badge-light-success">AKTİF</span>
                            <span v-else class="badge badge-lg badge-light-secondary">Tanımsız</span>
                          </div>
                          <div v-else-if="td.name == 'onay_durumu_id'" class="fw-bold">
                            <span v-if="tr[td.name] == 'Onaylandı'" class="badge badge-lg badge-light-success">{{
                              tr[td.name] }}</span>
                            <span v-else-if="tr[td.name] == 'Reddedildi'" class="badge badge-lg badge-light-danger">{{
                              tr[td.name] }}</span>
                            <span v-else-if="tr[td.name] == 'Bekliyor'" class="badge badge-lg badge-light-warning">{{
                              tr[td.name] }}</span>
                            <span v-else class="badge badge-lg badge-light-secondary">Tanımsız</span>
                          </div>
                          <div v-else-if="td.name == 'department_id'" class="fw-bold">
                            <span v-if="tr[td.name] == null" class="badge badge-lg badge-light-primary">işhesap
                              Muhasebe</span>
                            <span v-else class="badge badge-lg badge-light-info">{{ tr[td.name] }}</span>
                          </div>
                          <div v-else class="fw-bold">
                            {{ tr[td.name] }}
                          </div>
                        </div>
                      </div>
                    </td>
                  </template>
                  <td class="" v-if="button_visible.detail == true ||
                    button_visible.update == true ||
                    button_visible.delete == true
                    ">
                    <div class="d-flex justify-content-center align-items-center">
                      <button v-if="button_visible.detail == true" @click.stop="detailing(tr)" data-bs-toggle="offcanvas"
                        data-bs-target="#modalDetail" aria-controls="modalDetail"
                        class="btn btn-large btn-icon btn-bg-light btn-active-color-primary me-2">
                        <i class="bi bi-file-text"></i>
                      </button>
                      <button v-if="button_visible.update == true" @click.stop="editing(tr)"
                        class="btn btn-large btn-icon btn-bg-light btn-active-color-warning me-2"
                        data-bs-toggle="offcanvas" data-bs-target="#modalUpdate" aria-controls="modalUpdate">
                        <i class="bi bi-pencil-square"></i>
                      </button>
                      <button v-if="button_visible.delete == true" @click.stop="deleting(tr)"
                        class="btn btn-large btn-icon btn-bg-light btn-active-color-danger">
                        <i class="bi bi-trash3"></i>
                      </button>
                      <button v-if="button_visible.pay == true" @click="this.$router.push('/list/' + tr.id)"
                        class="btn btn-large btn-icon btn-bg-light btn-active-color-success">
                        <i class="bi bi-credit-card"></i>
                      </button>
                    </div>
                  </td>
                  <td v-if="button_visible.detay">
                    <button class="btn btn-light-primary btn-icon-primary btn-text-primary"
                      @click="this.$router.push('/musteriler/' + tr.id)">
                      <i class="ki-duotone ki-graph-3 fs-1"><span class="path1"></span><span class="path2"></span></i>
                      İşlemler</button>
                  </td>
                  <template v-else></template>
                </tr>
              </tbody>
            </table>
          </div>
          <button class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary" @click="show = !show"
            :class="this.show ? 'show menu-dropdown' : ''" data-kt-menu-trigger="click" data-kt-menu-attach="parent"
            data-kt-menu-placement="bottom-end">
            {{ limit }}
            <span v-if="show" class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M12.5657 11.3657L16.75 15.55C17.1642 15.9643 17.8358 15.9643 18.25 15.55C18.6642 15.1358 18.6642 14.4643 18.25 14.05L12.7071 8.50716C12.3166 8.11663 11.6834 8.11663 11.2929 8.50715L5.75 14.05C5.33579 14.4643 5.33579 15.1358 5.75 15.55C6.16421 15.9643 6.83579 15.9643 7.25 15.55L11.4343 11.3657C11.7467 11.0533 12.2533 11.0533 12.5657 11.3657Z"
                  fill="currentColor" />
              </svg></span>
            <span v-else class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                viewBox="0 0 24 24" fill="none">
                <path
                  d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                  fill="currentColor" />
              </svg></span>
          </button>
          <div
            class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-100px py-3"
            :class="this.show ? 'show' : ''" data-kt-menu="true" style="z-index: 105; position: absolute">
            <div class="menu-item px-3">
              <div class="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">
                Limit
              </div>
            </div>
            <div class="menu-item px-3">
              <a href="javascript:void(0)" class="menu-link px-3" :class="limit == 5 ? 'active' : ''"
                @click="changeLimit(5)">5</a>
            </div>
            <div class="menu-item px-3">
              <a href="javascript:void(0)" class="menu-link px-3" :class="limit == 10 ? 'active' : ''"
                @click="changeLimit(10)">10</a>
            </div>
            <div class="menu-item px-3">
              <a href="javascript:void(0)" class="menu-link px-3" :class="limit == 25 ? 'active' : ''"
                @click="changeLimit(25)">25</a>
            </div>
            <div class="menu-item px-3">
              <a href="javascript:void(0)" class="menu-link px-3" :class="limit == 50 ? 'active' : ''"
                @click="changeLimit(50)">50</a>
            </div>
            <div class="menu-item px-3">
              <a href="javascript:void(0)" class="menu-link px-3" :class="limit == 100 ? 'active' : ''"
                @click="changeLimit(100)">100</a>
            </div>
            <div class="menu-item px-3">
              <a href="javascript:void(0)" class="menu-link px-3" :class="limit == 200 ? 'active' : ''"
                @click="changeLimit(200)">200</a>
            </div>
          </div>
          <ul class="pagination">
            <li class="page-item previous" @click="first" :class="page == 1 ? 'd-none' : ''">
              <a class="page-link"><i class="las la-angle-double-left fs-4"></i></a>
            </li>
            <li class="page-item previous" @click="prev" :class="page - 1 < 1 ? 'd-none' : ''">
              <a class="page-link"><i class="previous"></i></a>
            </li>
            <li class="page-item" v-for="pg in data?.pages" :key="pg" @click="pagess(pg)" :class="pg == page
              ? 'active'
              : '' || pg < page - 3
                ? 'd-none'
                : '' || pg > page + 3
                  ? 'd-none'
                  : ''
              ">
              <a class="page-link" v-if="pg == page - 3">...</a>
              <a class="page-link" :class="pg == page - 3
                ? 'd-none'
                : '' || pg == page + 3
                  ? 'd-none'
                  : ''
                ">{{ pg }}</a>
              <a class="page-link" v-if="pg == page + 3">...</a>
            </li>
            <li class="page-item next" @click="next" :class="page + 1 > data?.pages ? 'd-none' : ''">
              <a class="page-link"><i class="next"></i></a>
            </li>
            <li class="page-item next" @click="last" :class="data?.pages == page ? 'd-none' : ''">
              <a class="page-link"><i class="las la-angle-double-right fs-4"></i></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="offcanvas offcanvas-end" tabindex="-1" id="modalUpdate" aria-labelledby="modalUpdateLabel">
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="modalUpdateLabel">Düzenle</h5>
      <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">
      <template v-for="edit in editColumn" :key="edit">
        <template v-if="input_visible[edit.name] == false"></template>
        <template v-else>
          <div v-if="edit.gui_type_name == 'select'" class="mb-3">
            <label :for="edit.name" class="form-label">{{
              edit.display_name
            }}</label>
            <select :id="edit.name" class="form-select" aria-label="Default select example">
              <option v-if="selectLoading">Yükleniyor...</option>
              <option v-for="s in selectDatas[edit.name]" :key="s" value="1">
                {{ s.text }}
              </option>
            </select>
          </div>
          <div v-else-if="edit.gui_type_name == 'date'" class="mb-3">
            <label :for="edit.name" class="form-label">{{
              edit.display_name
            }}</label>
            <input class="form-control form-control-solid" :id="edit.name" :placeholder="edit.display_name" type="date" />
          </div>
          <div v-else class="mb-3">
            <label :for="edit.name" class="form-label">{{
              edit.display_name
            }}</label>
            <input type="text" class="form-control" :id="edit.name" :placeholder="edit.display_name" />
          </div>
        </template>
      </template>
      <div class="d-flex align-items-center justify-content-end">
        <button class="btn btn-primary" @click="save()">Kaydet</button>
      </div>
    </div>
  </div>
  <div class="offcanvas offcanvas-end" tabindex="-1" id="modalDetail" aria-labelledby="modalDetailLabel">
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="modalDetailLabel">Detay</h5>
      <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">
      <div v-for="d in detailsCol" :key="d" class="my-4">
        <template v-if="this.column[d.name] == false"> </template>
        <div class="d-flex align-items-center justify-content-between" v-else>
          <div>{{ d.display_name }}</div>
          <div v-if="d.name == 'fatura_dosyasi'">
            <div v-for="ad in JSON.parse(detailsVal[d.name])" :key="ad">
              <a :href="this.baseUrl + ad['destination_path'] + ad['file_name']" download class="text-dark"
                title="İndir">{{ ad["file_name"] }}<i class="las la-download fs-2x"></i></a>
            </div>
          </div>
          <div v-else>{{ detailsVal[d.name] }}</div>
        </div>
      </div>
    </div>
  </div>
  <el-dialog v-model="reportModal" title="İndirme İşlemi" width="30%" @close="resetDownloadProgress">
    <div class="d-flex flex-column justify-content-center align-items-center">
      <el-progress type="circle" :percentage="downloadProgress" :color="colors" />
      <el-loading v-if="reportLoading" text="İndirme devam ediyor..."></el-loading>
      <div v-else>
        <h5>İndirme işlemi tamamlandı.</h5>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="reportModal = false">Kapat</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { ref } from "vue";
import axios from "axios";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
export default {
  props: {
    tableName: String,
    table_refresh: Number,
    searches: {
      type: Object,
      default: function () {
        return {};
      },
    }, //Arama
    record: {
      type: Object,
      default: function () {
        return {};
      },
    },
    filters: {
      type: Object,
      default: function () {
        return {};
      },
    }, //Filtreleme (Carinin alt sayfasındaki adresler gibi)
    column: {
      type: Object,
      default: function () {
        return {
          bayi_id: true,
          created_at: true,
          updated_at: true,
          description: false,
          e_kontor: false,
          e_onay: false,
          firma_email: true,
          firma_telefonu: true,
          firma_vkn: true,
          firma_websitesi: false,
          id: false,
          state: false,
          image: false,
          kayit_tarihi: true,
          manager_id: true,
          manager_id: false,
          mersis_no: false,
          musteri_paketi_id: true,
          name_basic: true,
          son_kullanma_tarihi: true,
          ticaret_sicil_no: false,
          updated_at: true,
          vergi_daireleri_id: true,
        };
      },
    },
    visible: {
      type: Object,
      default: function () {
        return {
          id: false,
          created_at: false,
          updated_at: false,
          own_id: false,
          user_id: false,
          department_id: false,
          donusturulme_zaman: false,
          master_description: false,
          odeme_yontemi_id: false,
          musteri_fiyati: false,
          bayi_id: false,
          garanti_belgesi: false,
          state: false,
        };
      },
    }, //Gizlenecek kolonlar
    input_visible: {
      type: Object,
      default: function () {
        return {};
      },
    }, //Gizlenecek inputlar(ekleme sayfasında state butonu gibi,isim inputu gibi)
    sort: {
      type: Object,
      default: function () {
        return {};
      },
    }, //Sıralama
    button_visible: {
      type: Object,
      default: function () {
        return {
          create: true,
          update: true,
          delete: true,
          detail: true,
          details: true,
          pay: true,
        };
      },
    },
  },
  data() {
    return {
      params: {},
      page: 1, //Sayfa numarası
      limit: 10, //Sayfada gösterilen kayıt sayısı
      sort: {}, //Sıralama
      sorts: this.sort, //Sıralama
      filter: {}, //Filtreleme
      data: [], //Veriler
      userData: [], //Kullanıcı verileri
      editColumn: [], //Düzenleme verileri
      selectDatas: [], //Select verileri
      detailsCol: [], //Detay verileri
      detailsVal: [], //Detay verileri
      dataCount: 0,
      loading: false,
      selectLoading: false,
      show: false,
      searched: "",

      downloadProgress: 0,
      reportLoading: false,
      reportModal: false,
      colors: [
        { color: "#f56c6c", percentage: 20 },
        { color: "#e6a23c", percentage: 40 },
        { color: "#5cb87a", percentage: 60 },
        { color: "#1989fa", percentage: 80 },
        { color: "#6f7ad3", percentage: 100 },
      ],
      filterVisible: false,
      years: [],
      radio2: ref(new Date().getFullYear()),
      months: [
        {
          id: 1,
          date: "01",
          date2: "02",
          name: "Ocak",
        },
        {
          id: 2,
          date: "02",
          date2: "03",
          name: "Şubat",
        },
        {
          id: 3,
          date: "03",
          date2: "04",
          name: "Mart",
        },
        {
          id: 4,
          date: "04",
          date2: "05",
          name: "Nisan",
        },
        {
          id: 5,
          date: "05",
          date2: "06",
          name: "Mayıs",
        },
        {
          id: 6,
          date: "06",
          date2: "07",
          name: "Haziran",
        },
        {
          id: 7,
          date: "07",
          date2: "08",
          name: "Temmuz",
        },
        {
          id: 8,
          date: "08",
          date2: "09",
          name: "Ağustos",
        },
        {
          id: 9,
          date: "09",
          date2: "10",
          name: "Eylül",
        },
        {
          id: 10,
          date: "10",
          date2: "11",
          name: "Ekim",
        },
        {
          id: 11,
          date: "11",
          date2: "12",
          name: "Kasım",
        },
        {
          id: 12,
          date: "12",
          date2: "01",
          name: "Aralık",
        },
      ],

      toplamlar: 0,
      payId: 0,
      iframe: false,
      token: "",
      baseUrl: "https://muhasebe.ishesap.com/",
      column_set_id: {},
      column_set_id1: {},
      formatter: new Intl.NumberFormat("tr-TR", {
        //style: 'currency',
        currency: "TRY",
        minimumFractionDigits: 2,
      }),
    };
  },
  methods: {
    first() {
      this.page = 1;
      this.getTable();
    }, //İlk sayfaya gönder
    prev() {
      this.page--;
      this.getTable();
    }, //Bir önceki sayfaya gönder
    next() {
      this.page++;
      this.getTable();
    }, //Bir sonraki sayfaya gönder
    last() {
      console.log(this.data.pages);
      this.page = this.data.pages;
      this.getTable();
    }, //Son sayfaya gönder
    pagess(pg) {
      this.page = pg;
      this.getTable();
    }, //Belirli sayfaya gönder
    changeLimit(limit) {
      this.limit = limit;
      this.page = 1;
      this.getTable();
      this.show = false;
    }, //Limit değiştir
    sortBy(td) {
      if (this.sorts[td.name] == true) {
        let value2 = JSON.parse('{"' + td.name + '":false}');
        this.sorts = value2;
      } else if (this.sorts[td.name] == false) {
        delete this.sorts[td.name];
      } else {
        let value3 = JSON.parse('{"' + td.name + '":true}');
        this.sorts = value3;
      }
      this.getTable();
    }, //Sıralama işlemi
    getTable() {
      this.loading = true;
      this.params = {
        page: this.page,
        limit: this.limit,
        column_array_id: this.getUserData.auths.tables[this.tableName].lists[0],
        column_array_id_query:
          this.getUserData.auths.tables[this.tableName].queries[0],
        sorts: this.sorts,
        filters: this.tableName == 'finansal_islemler' || this.searched != '' ? this.filter : this.filters,
        editMode: false,
        liveDataMode: false,
        columnNames: [],
        filterColumnNames: [],
      };
      axios
        .post(this.$store.getters.getToken + "/tables/" + this.tableName, {
          params: JSON.stringify(this.params),
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.dataCount = res.data.data.all_records_count;
            this.data = res.data.data;
            this.$emit("dataCount", this.dataCount);
            // for (const val of Object.values(res.data.data.records)) {
            //   console.log(val)
            //   let array = [val];
            //   this.$emit("dataCount", array.length);
            // }
            localStorage.setItem(this.tableName, JSON.stringify(this.data));
          }
        })
        .catch((err) => {
          console.log(err.response?.data.data.message);
          if (err.response?.data.data.message == "fail.token") {
            this.$store.dispatch("logOut");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    dropdownShow() {
      this.show = true;
    },
    setData(record) {
      console.log(record);
      axios
        .post(
          this.$store.getters.getToken +
          "/tables/" +
          this.tableName +
          "/" +
          record +
          "/update",
          {
            column_set_id: this.column_set_id,
            //id: record,
            in_form_column_name: "kayit_durum_id",
            kayit_durum_id: 2,
            single_column: "kayit_durum_id",
          }
        )
        .then((response) => {
          if (response.data.code == 200) {
            this.getTable();
            toastr.success(
              "Onay işlemi başarılı bir şekilde gerçekleşti.",
              "Başarılı!"
            );
          }
        })
        .catch((error) => {
          console.log(error);
          toastr.error("Hatalı işlem.", "Hata!");
        })
        .finally(() => {
          //this.getTable();
          this.loading = false;
        });
    },
    deleting(e) {
      Swal.fire({
        title: "Silme İşlemi",
        text: "Seçili veriyi silmek istediğinizden emin misiniz?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Evet",
        cancelButtonText: "Hayır",
        denyButtonText: "asad",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteAxios(e);
        }
      });
    },
    deleteAxios(e) {
      axios
        .post(
          this.$store.getters.getToken +
          "/tables/" +
          this.tableName +
          "/" +
          e.id +
          "/delete"
        )
        .then((res) => {
          if (res.data.data.message == "success") {
            toastr.success("Seçili işlem silindi.", "Başarılı!");
          } else if (res.data.data.message == "error") {
            toastr.error("Seçili işlem silinirken bir hata oluştu.", "Hata!");
          }
          this.getTable();
        })
        .catch((err) => {
          toastr.error(err.response.data.data.message, "Hata!");
        });
    },
    detailing(e) {
      axios
        .post(
          this.$store.getters.getToken +
          "/tables/" +
          this.tableName +
          "/" +
          e.id,
          {
            params: JSON.stringify({
              column_set_id:
                this.getUserData.auths.tables[this.tableName].shows[0],
            }),
          }
        )
        .then((res) => {
          this.detailsCol = res.data.data.column_set.column_arrays[0].columns;
          this.detailsVal = res.data.data.record;
        })
        .catch((err) => {
          toastr.error(err.response.data.data.message, "Hata!");
        });
    },
    editing(e) {
      console.log(e);
      axios
        .post(
          this.$store.getters.getToken +
          "/tables/" +
          this.tableName +
          "/" +
          e.id +
          "/edit",
          {
            params: JSON.stringify({
              column_set_id:
                this.getUserData.auths.tables[this.tableName].edits[0],
            }),
          }
        )
        .then((res) => {
          this.editColumn = res.data.data.column_set.column_arrays[0].columns;
          for (const edit of Object.values(this.editColumn)) {
            if (edit.gui_type_name == "select") this.selectData(edit);
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.data.message, "Hata!");
        });
    },
    selectData(e) {
      this.selectLoading = true;
      axios
        .post(
          this.$store.getters.getToken +
          "/tables/" +
          this.tableName +
          "/getSelectColumnData/" +
          e.name,
          {
            search: "***",
            limit: 1000000,
            upColumnName: e.up_column_name,
            // upColumnData: this.params?.[this.column.up_column_name],
          }
        )
        .then((res) => {
          this.selectDatas[e.name] = res.data.results;
        })
        .catch((err) => {
          toastr.error(err.response.data.data.message, "Hata!");
        })
        .finally(() => {
          this.selectLoading = false;
        });
    },
    save() {
      console.log("save");
    },
    searchTable(column) {
      this.filter = {
        [column]: {
          type: 1,
          guiType: "text",
          filter: this.searched,
        },
      };
      this.getTable();
    },
    detailRoutePush(id) {
      if (this.tableName == "ticket") this.$router.push("/ticket/" + id);
      else if (this.tableName == "bayi_odeme_gecmisi")
        this.$router.push("/financial/" + id);
    },
    exportData() {
      this.reportModal = true;
      this.reportLoading = true;
      const params = {
        page: this.page,
        limit: this.limit,
        column_array_id:
          this.getUserData?.auths?.tables[this.tableName]?.lists[0],
        column_array_id_query:
          this.getUserData?.auths?.tables[this.tableName]?.queries[0],
        sorts: [],
        filters: this.filters,
        columnNames: ["id"],
        report_format: "xlsx",
        record_id: 0,
        report_id: this.getUserData?.reports[this.tableName]?.table[0].id
          ? this.getUserData?.reports[this.tableName]?.table[0].id
          : 0,
      };
      const url =
        axios.defaults.baseURL +
        this.$store.getters.getToken +
        "/tables/" +
        this.tableName +
        "/report?" +
        "params=" +
        JSON.stringify(params);

      const iframe = document.createElement("iframe");
      iframe.style.display = "none";
      iframe.src = url;
      axios({
        method: "get",
        url: url,
        responseType: "arraybuffer",
        onDownloadProgress: (progressEvent) => {
          this.downloadProgress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
        },
      })
        .then((response) => {
          this.reportLoading = false;
          this.downloadProgress = 100;
          console.log(response);
        })
        .catch((error) => {
          console.error("İndirme hatası:", error);
        });

      document.body.appendChild(iframe);
    },
    resetDownloadProgress() {
      this.downloadProgress = 0;
    },
    yearsData() {
      for (let i = 0; i < 10; i++) {
        this.years.push(new Date().getFullYear() - i);
      }
    },
    changeFilters(date, date2) {
      let lastDayOfMonth = new Date(this.radio2, date, 0).getDate();

      if (date == 12) {
        this.filter = {
          duzenleme_tarihi: {
            type: 4,
            guiType: "date",
            filter: this.radio2 + '-' + date + '-01',
            filter2: (this.radio2 + 1) + '-' + date + '-' + lastDayOfMonth,
          },
        };
      } else {
        this.filter = {
          duzenleme_tarihi: {
            type: 4,
            guiType: "date",
            filter: this.radio2 + '-' + date + '-01',
            filter2: this.radio2 + '-' + date + '-' + lastDayOfMonth,
          },
        };
      }
      this.getTable();
    },
  },
  computed: {
    ...mapGetters(["getUserData"]),
  },
  mounted() {
    this.getTable();
    this.yearsData();
  },
  watch: {
    tableName() {
      this.filter = {};
      this.getTable();
    },
    table_refresh() {
      this.getTable();
    },
    filterVisible() {
      if (this.filterVisible == false && this.tableName == "finansal_islemler" && this.searched == "") {
        this.filter = {};
        this.getTable();
      }
    }
  },
};
</script>

<style>
.page-item {
  cursor: pointer;
}

.ozelClass {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.spinner {
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0 auto;
  width: 32px;
  height: 32px;
}

.spinner .path {
  stroke: #009ef7;
  stroke-linecap: round;
  -webkit-animation: dash 1.1s ease-in-out infinite;
  animation: dash 1.1s ease-in-out infinite;
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 160;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 80, 160;
    stroke-dashoffset: -32;
  }

  100% {
    stroke-dasharray: 80, 160;
    stroke-dashoffset: -124;
  }
}

/* Scrollbar Styling */
#table::-webkit-scrollbar {
  width: 10px !important;
}

#table::-webkit-scrollbar-thumb {
  background-color: #ebebeb !important;
  -webkit-border-radius: 10px !important;
  border-radius: 10px !important;
}

#table::-webkit-scrollbar-thumb:window-inactive {
  -webkit-border-radius: 10px !important;
  border-radius: 10px !important;
  background: #6d6d6d !important;
}</style>
